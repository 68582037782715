.panel {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 20;
  border-left: 1px solid var(--gray-light-alpha);
  background-color: var(--white);
  overflow-y: auto;
  overscroll-behavior: contain;
}

.panel::-webkit-scrollbar {
  width: 4px;
}

.panel::-webkit-scrollbar-track {
  background: var(--gray-light-alpha);
}

.panel::-webkit-scrollbar-thumb {
  background: var(--gray);
  border-radius: 4px;
}
