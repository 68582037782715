.markdown {
  padding: 20px;
  white-space: pre-wrap;
  user-select: text;
}

.markdown * {
  user-select: text;
}

.markdown h1 {
  font-size: 2em;
  font-weight: 600;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}

.markdown h2 {
  font-size: 1.5em;
  font-weight: 600;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.markdown h3 {
  font-size: 1.17em;
  font-weight: 600;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.markdown h4 {
  font-size: 1em;
  font-weight: 600;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}

.markdown h5 {
  font-size: 0.83em;
  font-weight: 600;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}

.markdown h6 {
  font-size: 0.67em;
  font-weight: 600;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
}

.markdown ul {
  list-style: circle;
  padding-left: 1.25em;
}

.markdown ol {
  list-style: decimal;
  padding-left: 1.25em;
}
