:root {
  /* colors */
  --white: #fff;
  --black: #000;
  --blue: #3857ff;
  --blue-light: #3d5afe;
  --gray: #75777a;
  --gray-light: #f5f5f5;
  --gray-lighten: #cccccc;
  --gray-light-alpha: rgba(0, 0, 0, 0.2);
  --gray-darker-alpha: rgba(0, 0, 0, 0.5);
  --coconut-white: #e9eef4;
  --alabaster: #fafafa;
  --bleached-silk: #f2f2f2;
  --red: #ff5252;
  --red-light: #ffe6e6;
  --green: #4dbe6b;
  /* border-radius */
  --br-none: 0px;
  --br-md: 12px;
  --br-full: 9999px;
}
