html {
  color: var(--black);
  font-size: 16px;
  font-family: IBMPlexSans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

html,
body,
#root {
  height: 100%;
}

*:not(input) {
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
