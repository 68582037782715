.checker {
  width: 1.375rem;
  height: 1.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid var(--gray-lighten);
  transition: all 0.3s ease;
}

.checker.checked {
  color: var(--blue);
  border: 1px solid var(--blue);
}
