.icon {
  font-size: inherit;
  color: inherit;
  width: 1em;
  height: 1em;
}

.icon.filled {
  fill: currentColor;
}

.icon.stroked {
  stroke: currentColor;
}
