@font-face {
  font-family: 'IBMPlexSans';
  src: local('IBMPlexSans-Light'),
    url('../../assets/fonts/IBMPlexSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: local('IBMPlexSans-Regular'),
    url('../../assets/fonts/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: local('IBMPlexSans-Medium'),
    url('../../assets/fonts/IBMPlexSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: local('IBMPlexSans-SemiBold'),
    url('../../assets/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
