.checkbox {
  max-width: 220px;
}

.checkbox.wide {
  width: 100%;
  max-width: none;
}

.checkbox .input {
  display: none;
}

.checkbox .label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox.reversed .label {
  flex-direction: row-reverse;
}

.checkbox .label .label_text {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--black);
}
