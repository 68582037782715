.log {
  background-color: var(--alabaster);
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  border-bottom: 1px solid var(--gray-light-alpha);
}

.log.active {
  background-color: var(--coconut-white);
}

.log:hover {
  background-color: var(--coconut-white);
}
