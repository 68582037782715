.box {
  display: block;
  box-sizing: border-box;
}

.box.fill .image {
  object-fit: fill;
}

.box.contain .image {
  object-fit: contain;
}

.box.cover .image {
  object-fit: cover;
}

.box.none .image {
  object-fit: none;
}

.box.scale-down .image {
  object-fit: scale-down;
}

.image {
  width: 100%;
  height: 100%;
}
