.button {
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
}

.button.disabled {
  opacity: 0.7;
}

.button.loading .text {
  opacity: 0;
}

.button.wide {
  width: 100%;
}

.inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: inherit;
  font-weight: inherit;
  line-height: 1.25;
  letter-spacing: 0.4px;
  transition: all 0.3s ease-in-out;
}

/* sizes */
.button.xs {
  padding: 2px 6px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 400;
}

.button.sm {
  padding: 3px 8px;
  font-size: 16px;
  font-weight: 500;
}

.button.md {
  padding: 4px 16px;
  font-size: 18px;
  font-weight: 500;
}

.button.lg {
  padding: 12px 30px;
  font-size: 24px;
  font-weight: 600;
}

/* themes & colors */
.button.default.light {
  background-color: var(--white);
  color: var(--black);
}

.button.default.dark {
  background-color: var(--black);
  color: var(--white);
}

.button.default.blue {
  background-color: var(--blue);
  color: var(--white);
}

.button.plain.light {
  color: var(--white);
}

.button.plain.black {
  color: var(--black);
}

.button.plain.blue {
  color: var(--blue);
}

.button.outline.light {
  border-color: var(--white);
  color: var(--white);
}

.button.outline.dark {
  border-color: var(--black);
  color: var(--black);
}

.button.outline.blue {
  border-color: var(--blue);
  color: var(--blue);
}

/* shapes */
.button.square {
  border-radius: var(--br-none);
}

.button.square.xs {
  padding: 3px;
}

.button.square.sm {
  padding: 6px;
}

.button.square.md {
  padding: 16px;
}

.button.square.lg {
  padding: 30px;
}

.button.rectangle {
  border-radius: var(--br-none);
}

.button.rounded {
  border-radius: var(--br-md);
}

.button.circle {
  border-radius: var(--br-full);
}

/* loader */
.loader {
  height: 100%;
  position: absolute;
  right: 50%;
  top: 0px;
  transform: translateX(50%);
  display: flex;
  align-items: center;
}
