.slider {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.slider .content_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider .content {
  height: 100%;
  display: flex;
  transition: all 0.25s ease-in-out;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider .content::-webkit-scrollbar,
.slider .content::-webkit-scrollbar {
  display: none;
}

.slider .content > * {
  width: 100%;
  flex-shrink: 0;
}

.show-2 > * {
  width: 50% !important;
  flex-shrink: 1;
}

.show-3 > * {
  width: calc(100% / 3) !important;
  flex-shrink: 1;
}

.show-4 > * {
  width: 25% !important;
  flex-shrink: 1;
}

.show-5 > * {
  width: calc(100% / 5) !important;
  flex-shrink: 1;
}

.slider .actions_wrapper {
  width: 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--white);
}

@media (max-width: 768px) {
  .slider .actions_wrapper {
    display: none;
  }
}
