.collapser {
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 40px 16px 16px;
  background-color: var(--gray-light);
}

.collapser .wrapper {
  height: 0px;
  overflow: hidden;
  transition: height 0.3s ease;
  position: relative;
}

.collapser .content {
  transition: none;
}

.toggler {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.toggler .icon {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.toggler .icon.opened {
  transform: rotate(0deg);
}
