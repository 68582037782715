.wrapper {
  width: 100%;
  box-sizing: border-box;
}

.wrapper .input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.75rem;
  background-color: var(--bleached-silk);
  outline: none;
  border: none;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--black);
  transition: all 0.3s ease;
}

.wrapper .input.error {
  background-color: var(--red-light);
}

.wrapper .error_box {
  padding-top: 0.25rem;
  padding-left: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper .error_box .error_text {
  font-size: 0.875rem;
  line-height: 1.35;
  color: var(--red);
}

.wrapper .input::placeholder {
  color: var(--gray-darker-alpha);
}
