.loader {
  width: 1em;
  height: 1em;
  border-width: 0.15em;
  border-style: solid;
  border-color: currentColor;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotate 1s linear infinite;
}

.loader.light {
  color: var(--white);
}
.loader.dark {
  color: var(--black);
}
.loader.blue {
  color: var(--blue);
}
.loader.inherit {
  color: inherit;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
